import * as React from 'react'

interface IProps {
  withMargin: boolean
  onClick?: (e: React.SyntheticEvent) => void
  src: string
}

const PreviewImage: React.FunctionComponent<IProps> = ({ withMargin, onClick, src }) => {
  return (
    <div
      className={`preview-bg ${withMargin ? 'with-margin' : ''}`}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className="image" style={{ backgroundImage: `url(${src})` }} />
    </div>
  )
}

export { PreviewImage }
