import React from 'react'
import FacebookIcon from '!svg-react-loader!images/igniter/svgs/facebook-old.svg'
import TwitterIcon from '!svg-react-loader!images/igniter/svgs/twitter.svg'
import EmbedIcon from '!svg-react-loader!images/igniter/svgs/embed.svg'

export class ShareLinks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      embedsOpen: false,
    }
  }

  twitterURL() {
    const { product } = this.props
    return `https://twitter.com/share?url=${product.short_url}&related=ignitermedia&text=${product.name} (via @ignitermedia)`
  }

  facebookURL() {
    const { product } = this.props
    return `https://www.facebook.com/sharer.php?u=${product.full_url}`
  }

  toggleEmbedLink() {
    const { embedsOpen } = this.state

    this.setState({
      embedsOpen: !embedsOpen,
    })
  }

  render() {
    const { embedsOpen } = this.state
    const { product, ui2024 } = this.props
    const { site_nav_parent_tag: siteNavParentTag } = product

    let producerLink
    let shareLink

    const embedCode = `<iframe type="text/html" width="618" height="348" src="${product.embed_url}" frameborder="0" scrolling="no" />`

    const embedSection = (
      <div className="embed-container">
        <h3>Embed This Video</h3>
        <p>Copy the code below and paste it into your website.</p>
        <textarea
          defaultValue={embedCode}
          onClick={(e) => e.target.select()}
          readOnly />
      </div>
    )

    const embedLink = (
      <a href="#" onClick={() => this.toggleEmbedLink()}>
        <EmbedIcon />
      </a>
    )

    const showShareLinks = () => {
      const AUDIO_PRODUCTS = ['Stock Music', 'Sound Effects']
      return !product.stock || (product.stock && AUDIO_PRODUCTS.includes(siteNavParentTag.name))
    }

    if (showShareLinks()) {
      shareLink = (
        <div className="title-section">
          <h3>{product.name}</h3>
          { (!ui2024) ?
            <>
            <a href={this.facebookURL()} target="_blank">
              <FacebookIcon />
            </a>
            <a href={this.twitterURL()} target="_blank">
              <TwitterIcon />
            </a>
            </> : null }
          { product.embed_url ? embedLink : null }
        </div>
      )
    }

    if (product.producer) {
      const producerOrStockMusicArtist = () => {
        if (product.stock_music_artist.hasOwnProperty("name")) {
          const { name } = product.stock_music_artist
          return <span className="producer-name">{name}</span>
        } else {
          const { id, name, link, bug_logo: bugLogo } = product.producer

          const innerContent =
            <>
              <span dangerouslySetInnerHTML={{ __html: bugLogo }} />
              <span className="producer-name">{name}</span>
            </>

          // Don't link to Audiio producer page
          if (id == 58) {
            return (
              <div className="producer-logo">{innerContent}</div>
            )
          } else {
            return (
              <a href={link} className="producer-logo">{innerContent}</a>
            )
          }
        }
      }

      producerLink = (
        <div className="producer">
          <span className="producer-label">Created by:</span>
          {producerOrStockMusicArtist()}
        </div>
      )
    }

    return (
      <div className="share">
        { shareLink }
        { producerLink }
        { embedsOpen ? embedSection : null }
      </div>
    )
  }
}
