import * as React from 'react'
import { PreviewImage } from './PreviewImage'
import { hasStandardAspect } from './tools'
import { IImage } from './types'

interface IProps {
  images: IImage[]
  selectedImage: IImage
  setSelectedImage: (image: IImage) => void
  siteProductType: string
}

const ThumbnailList: React.FunctionComponent<IProps> = ({
  images,
  selectedImage,
  setSelectedImage,
  siteProductType,
}) => {
  return (
    <>
      {images.map((image) => (
        <div
          key={image.thumb_image_id}
          className={`preview-slide ${
            selectedImage === image ? 'selected' : ''
          }`}
        >
          <PreviewImage
            onClick={() => setSelectedImage(image)}
            src={image.thumb_image_uri}
            withMargin={!hasStandardAspect({ image, siteProductType })}
          />
        </div>
      ))}
    </>
  )
}

export { ThumbnailList }
