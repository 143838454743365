import * as React from 'react'
import { ThumbnailList } from './ThumbnailList'
import { IImage } from './types'

interface IProps {
  images: IImage[]
  selectedImage: IImage
  setSelectedImage: (image: IImage) => void
  siteProductType: string
}

const GalleryControls: React.FunctionComponent<IProps> = ({
  images,
  selectedImage,
  setSelectedImage,
  siteProductType,
}) => {
  const moveImageSelection = (direction: number) => {
    const indexOfSelected = images.indexOf(selectedImage)

    let newIndex = indexOfSelected + direction
    if (newIndex < 0) {
      newIndex = images.length - 1
    }
    setSelectedImage(images[newIndex % images.length])
  }

  return (
    <div className="thumbnail-row">
      <button className="previous" onClick={() => moveImageSelection(-1)}>
        <i className="fa fa-angle-left" />
      </button>

      <ThumbnailList
        images={images}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        siteProductType={siteProductType}
      />

      <button className="next" onClick={() => moveImageSelection(1)}>
        <i className="fa fa-angle-right" />
      </button>
    </div>
  )
}

export { GalleryControls }
