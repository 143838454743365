import React from 'react'
import { BookmarkDialog } from './BookmarkDialog'

export class PreviewMetadata extends React.Component {
  constructor(props) {
    super(props)

    this.toggleBookmarkModal = this.toggleBookmarkModal.bind(this)

    this.state = {
      bookmarkDialogOpen: false,
    }
  }

  toggleBookmarkModal() {
    this.setState((prevState, props) => {
      return {
        bookmarkDialogOpen: !prevState.bookmarkDialogOpen
      }
    })
  }

  render() {
    const {
      bookmarkDialogOpen,
    } = this.state
    const {
      viewCount,
      isUserSignedIn,
      productID,
    } = this.props
    const formattedViewCount = viewCount.toLocaleString()

    const bookmarkButton = (
      <div className="bookmark">
        <a href="#" title="Add Bookmark" onClick={this.toggleBookmarkModal}>
          <i className="fa fa-bookmark-o"></i>
        </a>

        <BookmarkDialog
          isOpen={bookmarkDialogOpen}
          onClose={this.toggleBookmarkModal}
          productID={productID}
        />
      </div>
    )

    return (
      <div className="preview-data clearfix">
        <div className="views">
          <span>Views: {formattedViewCount}</span>
        </div>

        { isUserSignedIn ? bookmarkButton : null }
      </div>
    )
  }
}
