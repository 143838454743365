import * as React from 'react'
import { Cell, Grid } from 'react-foundation'
import { AudioPlayer } from '../../ProductList/components/AudioProduct/AudioPlayer'
import { useAudioControls } from '../../ProductList/components/AudioProduct/hooks/use_audio_controls'
import { Waveform } from '../../ProductList/components/AudioProduct/Waveform'

import AudioPauseIcon from '!svg-react-loader!images/igniter/svgs/audio-pause.svg'
import AudioPlayIcon from '!svg-react-loader!images/igniter/svgs/audio-play.svg'

import { Duration } from 'luxon'

interface IProps {
  product: IProduct
}

const AudioPreview: React.FC<IProps> = (
  { product },
) => {
  const {
    id: productId,
    duration,
    main_image_url: mainImageUrl,
    name,
    video_url: videoUrl,
  } = product

  const controls = useAudioControls()
  const durationString = Duration.fromMillis((duration || 0) * 1000).toFormat(
    'm:ss',
  )

  return (
    <Grid className="audio-preview" data-playing={controls.playing}>
      <Cell shrink="medium" className="">
        <div className="album-art-container">
          <a
            className="album-art"
            href="#"
            title={name}
            onClick={(e) => { e.preventDefault(); controls.toggle() }}
          >
            <img className="image" src={mainImageUrl} />
            {controls.playing ? <AudioPauseIcon /> : <AudioPlayIcon />}
          </a>
        </div>
        <AudioPlayer
          src={videoUrl}
          controls={controls}
          id={productId}
        />
      </Cell>
      <Cell shrink="medium" className="waveform-container show-for-medium">
        <Waveform
          productId={productId}
          controls={controls}
        />
        <div className="duration">
          {durationString}
        </div>
      </Cell>
    </Grid>
  )
}

export { AudioPreview }
