import '../../../polyfills';
import WebpackerReact from 'webpacker-react';

import { BookmarkDialog } from '../../../ProductShow/components/BookmarkDialog';
import { ProductBucket } from '../../../ProductBucket';
import {
  ProductAudioPreview,
  ProductShareLinks,
  ProductPreviewGallery,
  ProductPreviewMetadata,
} from '../../../ProductShow';
import { VideoPlayer } from '../../../VideoPlayer';

WebpackerReact.setup({
  BookmarkDialog,
  ProductAudioPreview,
  ProductBucket,
  ProductShareLinks,
  ProductPreviewGallery,
  ProductPreviewMetadata,
  VideoPlayer,
});
