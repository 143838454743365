import { IImage } from './types'

const DEFAULT_ASPECT = '16:9'
const TYPE_SPECIFIC_STANDARD_ASPECTS = {
  'ultra-wides': '4:1',
  'stock-photos': '3:2',
}

const DEFAULT_FUDGE = 0
const FUDGE_FACTORS = {
  'stock-photos': 0.01,
}

export function hasStandardAspect({
  image,
  siteProductType,
}: {
  image: IImage
  siteProductType: string
}) {
  const currentAspect = image.aspect || DEFAULT_ASPECT
  const expectedAspect =
    TYPE_SPECIFIC_STANDARD_ASPECTS[siteProductType] || DEFAULT_ASPECT

  return (
    currentAspect === expectedAspect ||
    withinFudgeFactor({ currentAspect, expectedAspect, siteProductType })
  )
}

const withinFudgeFactor = ({
  currentAspect,
  expectedAspect,
  siteProductType,
}: {
  currentAspect: string
  expectedAspect: string
  siteProductType: string
}): boolean => {
  const current = convertAspectToRatio(currentAspect)
  const expected = convertAspectToRatio(expectedAspect)
  const fudge = FUDGE_FACTORS[siteProductType] || DEFAULT_FUDGE

  if (!current || !expected) {
    return false
  }

  return Math.abs((expected - current) / expected) < fudge
}

const convertAspectToRatio = (aspect: string): number | null => {
  const [width, height] = aspect.split(':')

  if (width && height) {
    return parseInt(width, 10) / parseInt(height, 10)
  } else {
    return null
  }
}
