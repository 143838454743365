import React from 'react';
import { Modal } from 'Modal';

export class BookmarkDialog extends React.Component {
  constructor(props) {
    super(props);

    this.fetchFolders = this.fetchFolders.bind(this);
    this.addBookmark = this.addBookmark.bind(this);
    this.updateSelectedFolder = this.updateSelectedFolder.bind(this);
    this.showAddNewFolderForm = this.showAddNewFolderForm.bind(this);
    this.hideAddNewFolderForm = this.hideAddNewFolderForm.bind(this);

    this.state = {
      folders: [],
      selectedFolderID: '',
      addNewFolderFormShown: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      folders,
    } = this.state;

    if (nextProps.isOpen && folders.length === 0) {
      this.fetchFolders();
    }
  }

  fetchFolders(selectedFolderID) {
    $.getJSON(Routes.igniter_bookmark_folders_path({ all: true }), (data) => {
      this.setState({
        folders: data.bookmark_folders,
        selectedFolderID: selectedFolderID || data.bookmark_folders[0].id,
      });
    });
  }

  updateSelectedFolder(event) {
    this.setState({
      selectedFolderID: parseInt(event.target.value),
    });
  }

  showAddNewFolderForm() {
    this.setState({
      addNewFolderFormShown: true,
    });
  }

  hideAddNewFolderForm() {
    this.setState({
      addNewFolderFormShown: false,
    });
  }

  addBookmark() {
    const {
      productID,
      onClose,
    } = this.props;
    const {
      selectedFolderID,
    } = this.state;

    $.post({
      url: Routes.igniter_bookmarks_path(),
      data: {
        bookmark: {
          product_id: productID,
          folder_id: selectedFolderID,
        }
      },
      complete: (xhr) => {
        onClose();
      },
    })
}

  render() {
    const {
      folders,
      selectedFolderID,
      addNewFolderFormShown
    } = this.state;
    const {
      isOpen,
      onClose,
    } = this.props;

    const folderOptions = folders.map((folder) => {
      return <option key={folder.id} value={folder.id}>{folder.name}</option>;
    });

    let modalContent = null;
    if (addNewFolderFormShown) {
      modalContent = (
        <CreateFolderInlineForm
          onAddFolder={this.fetchFolders}
          hideAddNewFolderForm={this.hideAddNewFolderForm} />
      );
    } else {
      modalContent = (
        <AddToFolderForm
          selectedFolderID={selectedFolderID}
          updateSelectedFolder={this.updateSelectedFolder}
          folderOptions={folderOptions}
          showAddNewFolderForm={this.showAddNewFolderForm}
          addBookmark={this.addBookmark}
          onClose={onClose} />
      );
    }

    return (
      <Modal
        className="add-bookmark-dialog"
        isOpen={isOpen}
        onRequestClose={onClose}
        aria={{
          labelledby: "bookmark_dialog_heading",
        }}
      >
        <h3 id="bookmark_dialog_heading">Add Bookmark</h3>

        {modalContent}
      </Modal>
    );
  }
}

const AddToFolderForm = (props) => {
  const {
    selectedFolderID,
    updateSelectedFolder,
    folderOptions,
    showAddNewFolderForm,
    addBookmark,
    onClose,
  } = props;

  return [
    <p key="lead" className="lead">
      Add to your
      <select value={selectedFolderID} onChange={updateSelectedFolder}>
        {folderOptions}
      </select>
      <strong>Folder</strong>
    </p>,
    <a href="#" key="show-form" onClick={showAddNewFolderForm}>
      + Create a New Folder
    </a>,
    <div key="actions" className="actions">
      <button
        className="button primary"
        onClick={addBookmark}
        disabled={!selectedFolderID}
      >
        Add Bookmark
      </button>
      <button className="button tertiary" onClick={onClose}>Cancel</button>
    </div>
  ];
};

class CreateFolderInlineForm extends React.Component {
  constructor(props) {
    super(props);

    this.addFolder = this.addFolder.bind(this);
  }

  addFolder(event) {
    const { onAddFolder, hideAddNewFolderForm } = this.props;
    event.preventDefault();

    $.post({
      url: Routes.igniter_bookmark_folders_path(),
      data: {
        bookmark_folder: {
          name: this.input.value,
        }
      },
      success: (data) => {
        this.input.value = "";
        onAddFolder(data.bookmark_folder.id);
        hideAddNewFolderForm();
      },
    })
  }

  render() {
    const { hideAddNewFolderForm } = this.props;

    return (
      <div className="add-folder">
        <form onSubmit={this.addFolder}>
          <input type="text" required placeholder="Folder Name" ref={(input) => this.input = input} />

          <div className="actions">
            <button type="submit" className="button primary">Create Folder</button>
            <button className="button secondary" onClick={hideAddNewFolderForm}>Cancel</button>
          </div>
        </form>
      </div>
    );
  }
}
