import * as React from 'react'
import { GalleryControls } from './GalleryControls'
import { PreviewImage } from './PreviewImage'
import { hasStandardAspect } from './tools'
import { IImage } from './types'

interface IProps {
  siteProductType: string
  images: IImage[]
}

const PreviewGallery: React.FunctionComponent<IProps> = ({
  siteProductType,
  images,
}) => {
  const [selectedImage, setSelectedImage] = React.useState<IImage>(images[0])

  return (
    <div className={`preview-gallery ${siteProductType}`}>
      <PreviewImage
        src={selectedImage.main_image_uri}
        withMargin={!hasStandardAspect({ image: selectedImage, siteProductType })}
      />

      {images.length > 1 ? (
        <>
          <GalleryControls
            images={images}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            siteProductType={siteProductType}
          />
          {images.map((image) => (
            <div key={image.thumb_image_id} className="eager-load-image">
              <img src={image.main_image_uri} />
            </div>
          ))}
        </>
      ) : null}
    </div>
  )
}

export { PreviewGallery }
